<template>
  <app-side-menu>
    <aside
      class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input
                class="input is-capitalized"
                type="text"
                placeholder="Remark Description"
                v-model="value.remark"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field has-addons">
            <bulma-radio-button
              :native-value="0"
              v-model="value.active">
              <span class="icon is-small">
                <i class="mdi mdi-adjust" />
              </span>
              Both
            </bulma-radio-button>
            <bulma-radio-button
              type="is-success"
              :native-value="1"
              v-model="value.active">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span>
              Active
            </bulma-radio-button>
            <bulma-radio-button
              type="is-danger"
              :native-value="2"
              v-model="value.active">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span>
              In-Active
            </bulma-radio-button>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button
            class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button
            class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { BulmaRadioButton } from '@/components/BulmaRadio'

export default {
  name: 'RecurringremarkListSideMenu',
  inject: ['$vv'],
  components: {
    AppSideMenu,
    BulmaRadioButton
    // AppSideMenu: () => import('@/components/AppSideMenu/AppSideMenu.vue').then((obj) => {
    //   console.log(obj, 'loaded')
    //   return obj
    // }),
  },
  props: {
    filter: null,
    value: null
  },
  data() {
    return {
      selectedActive: 1
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    // if (this.value.dateFrom) {
    //   this.dateFromFilter = new Date(this.value.dateFrom).toLocaleDateString(this.$userInfo.locale)
    // }
    // if (this.value.dateTo) {
    //   this.dateToFilter = new Date(this.value.dateTo).toLocaleDateString(this.$userInfo.locale)
    // }
  },
  mounted() {},
  beforeDestroy() {
    const elements = document.getElementsByClassName('datepicker')
    Array.from(elements).forEach((element, index, array) => {
      const parent = element.parentNode
      // console.log(parent.nodeType, element)
      if (parent.nodeName === 'BODY') {
        parent.removeChild(element)
      }
    })
    // while (elements.length > 0) {
    //   const parent = elements[elements.length - 1].parentNode
    //   console.log(parent, elements[elements.length - 1])
    //   parent.removeChild(elements[elements.length - 1])
    // }
  },
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      this.$emit('reset')
    },
    hide(event) {
      // console.log(event)
      if (!event.relatedTarget || (event.relatedTarget.classList && !event.relatedTarget.classList.contains('date-item'))) {
        // this.fromDatePicker.hide()
        // this.toDatePicker.hide()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';
input.uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>

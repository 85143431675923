import { required } from 'vuelidate/lib/validators'

export default {
  provide: function() {
    return {
      $vv: this.$v
    }
  },
  validations: {
    detailGroup: ['entity.remark'],
    entity: {
      remark: {
        required
      }
    },
    filter: {}
  }
}

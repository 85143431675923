<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-parent is-8">
      <article class="tile is-child box detail-page-tile">
        <p class="title">Detail Info</p>
        <div class="field">
          <label class="label">Remark Description</label>
          <p class="control">
            <textarea
              class="textarea"
              v-focus-inserted
              v-model="value.remark"
              :class="{'is-danger' : $v.entity.remark.$error, 'is-warning' : remarkExists}"
              @input="$v.entity.remark.$touch(); checkRemarkExists()" />
          </p>
          <span
            class="help is-danger"
            v-if="$v.entity && !$v.entity.remark.required">Remark Description is required.</span>
          <span
            class="help is-warning"
            v-if="remarkExists">Remark Description already exists.
          </span>
        </div>
        <div class="is-divider" />
        <div class="field">
          <div class="buttons has-addons is-centered">
            <span
              class="button"
              :class="{ 'is-success is-selected' : value.isActive }"
              @click="toggleActive(true)">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span>
              <span>Active</span>
            </span>
            <span
              class="button"
              :class="{ 'is-danger is-selected' : !value.isActive }"
              @click="toggleActive(false)">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span>
              <span>In-Active</span>
            </span>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import RecurringRemarkService from './RecurringRemarkService'
import { FocusInserted } from '@/components/directives'
import StoreMixin from './storeMixin'
import _debounce from 'lodash.debounce'

export default {
  name: 'RecurringremarkDetail',
  inject: ['$vv'],
  components: {},
  directives: {
    FocusInserted
  },
  mixins: [StoreMixin],
  props: {
    value: null,
    isNew: Boolean
  },
  data() {
    return {
      remarkExists: false
    }
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {},
  mounted() {
    this.$v.detailGroup.$touch()
  },
  methods: {
    toggleActive(active) {
      this.value.isActive = active
    },
    checkRemarkExists: _debounce(async function() {
      await this.assertRemarkExists()
    }, 500),
    async assertRemarkExists() {
      this.remarkExists = await RecurringRemarkService.assertRemarkExists(this.value.id, this.value.remark)
    }
  }
}
</script>
<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
.desc-width {
  width: 60%;
}
</style>

<template>
  <div>
    <app-detail-header
      :show-toggle="true"
      :show-new="false"
      :show-print="entity && !entity.isNew"
      :show-delete="entity && entity.isActive && !entity.isNew"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <recurringremark-side-menu
          v-if="entity"
          ref="sideMenu" />
      </div>
      <div class="column">
        <router-view
          v-if="entity"
          v-model="entity"
          ref="currentChild"
          :is-tiled="false"
          :key="$route.fullPath"
          :route-name="$route.name"
          @entityDelete="onEntityDelete()" />
      </div>
    </div>
    <unsaved-modal
      :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal
      :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
  </div>
</template>

<script>
import RecurringRemarkSideMenu from './RecurringRemarkSideMenu'
import RecurringRemarkService from './RecurringRemarkService'
import RecurringRemarkValidation from './RecurringRemarkValidation'
import RecurringRemarkRoutes from './route-types'
import AppDetailHeader from '@/components/AppDetailHeader'
import { UnsavedModal, SaveConflictModal } from '@/components/BulmaModal'
import HttpStatus from '@/components/http-status'
import EventBus from '@/components/EventBus'
import { AppHeaderButtonTypes, EventHubTypes } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import _isEmpty from 'lodash/isEmpty'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { Emailer } from '@/classes'
import { KeyValuePairModel } from '@/classes/viewmodels'

export default {
  name: 'RecurringRemarkView',
  components: {
    AppDetailHeader,
    [RecurringRemarkSideMenu.name]: RecurringRemarkSideMenu,
    UnsavedModal,
    SaveConflictModal
  },
  mixins: [RecurringRemarkValidation, StoreMixin],
  props: {
    isNew: Boolean,
    returnUrl: String,
    type: String
  },
  data() {
    return {
      entity: null,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      deleteAndSave: false,
      isSkipSave: false,
      isSaveContinue: false,
      toRoute: null // stores "to" route of beforeRouteLeave
    }
  },
  computed: {
    validateError() {
      return this.$v.$error || this.$v.detailGroup.$error
    }
  },
  watch: {
    entity: {
      handler: _debounce(function(newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 500),
      deep: true
    }
  },
  beforeDestroy() {
    // this.clearSessionStorage()
    // this.clearSnapshots(this.entity.recurringRemarkId)
  },
  async created() {
    if (this.returnUrl) {
      this.persistQueries()
      this.replaceRoute(this.$route.params.id)
    }
    this.getEntity()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          this.print1()
          break
        case AppHeaderButtonTypes.Delete:
          this.entity.isActive = false
          this.save(true)
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.$route.params.id)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.id)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(isDelete) {
      const title = 'Recurring Remark'
      if (this.validateError) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }
      this.snapshotDiff
      try {
        this.$showSpinner('Saving...')
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          await RecurringRemarkService.postRecurringRemark(this.entity)
        } else if (this.snapshotDiff) {
          await RecurringRemarkService.putRecurringRemark(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes detected. Not saved')
          return false
        }
        // await this.removeStoreItem(this.entity.id)
        this.$notification.success('Recurring Remark', isDelete ? 'Recurring Remark set to In-Active' : 'Save successful')
        if (!this.isSaveContinue) {
          await this.getEntity(true)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError('Recurring Remark', e)
        }
        return false
      } finally {
        this.$hideSpinner()
      }
    },
    async deleteEntity(check, value, saveEntity) {
      this.entity.active = value
      if (saveEntity) {
        this.save(true)
      }
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.returnRoute && !_isEmpty(this.returnRoute)) {
        this.$router.push({
          path: this.returnRoute.path,
          query: this.returnRoute.query
        })
      } else if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else {
        // const returnUrl = sessionStorage.getItem(this.returnUrlKey)
        // if it comes from url => redirect after save
        if (this.returnUrl) {
          if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
            this.isUnsavedModalActive = true
          } else {
            this.clearSessionStorage()
            this.clearSnapshots(this.entity.id)
            this.$showSpinner('Returning...')
            this.redirectToQueryUrl(this.returnUrl)
          }
        } else {
          if (this.entity.isNew && this.isSkipSave) {
            // cleanup vuex store
            this.clearSnapshots(this.entity.id)
          }
          this.$router.push({
            name: RecurringRemarkRoutes.RecurringRemarkListView.name,
            filter: this.filter,
            query: {
              filter: this.encodedFilter
            }
          })
        }
      }
    },
    print() {
      const params = {
        reportname: 'rptRecurringRemarkDetail',
        ModelCustomID: this.entity.recurringRemarkId,
        CompanyID: this.$userInfo.companyId
      }
      const url =
        `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx?` +
        Object.keys(params)
          .map(e => `${e}=${params[e]}`)
          .join('&')
      window.open(url, '_blank')
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    print1() {
      const params = Object.assign({
        CompanyID: this.$userInfo.companyId,
        RecurringRemarkID: this.entity.id
      })
      // const query = this.$lzstring.compressToEncodedURIComponent(
      //   Object.keys(params)
      //     .map(e => `${e}=${params[e]}`)
      //     .join('&')
      // )
      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)

      const emailer = new Emailer()
      emailer.assetId = this.entity.id
      emailer.subject = 'Recurring Remarks - Details'
      emailer.reportName = 'Recurring Remarks - Details'
      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: this.$route.meta.report },
        query: { parameterId:  this.$guid.newGuid(), emailerId: emailer.id }
      })
      // window.open(printPreviewRoute.href, '_blank')
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    redirectToRoute() {
      if (this.toRoute) {
        this.$router.push(this.toRoute.fullPath)
      } else if (!this.entity.isNew) {
        this.cancel()
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      this.toRoute = null
    },
    async reloadData() {
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
      await this.getEntity(true)
      // this.$eventHub.$emit(EventHubTypes.EntityReload)
    },
    persistQueries() {
      if (this.returnUrl) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|recurringremark|returnUrl`, this.returnUrl)
      }
      if (this.type) {
        sessionStorage.setItem(`${this.$userInfo.sessionId}|recurringremark|type`, this.type)
      }
    },
    removeQueries() {
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|recurringremark|returnUrl`)
      sessionStorage.removeItem(`${this.$userInfo.sessionId}|recurringremark|type`)
    },
    clearSessionStorage() {
      this.removeQueries()
    },
    replaceRoute(id) {
      const newMeta = Object.assign(this.$route.meta, {
        returnUrl: this.returnUrl,
        type: this.type
      })
      this.$router.replace({
        name: RecurringRemarkRoutes.RecurringRemarkDetail.name,
        params: { recurringRemarkId: id },
        meta: newMeta
      })
    },
    clearRouteMeta() {
      // Reset returnUrl meta in case user clicked on other V2
      if (this.$route.meta.returnUrl) {
        this.$route.meta.returnUrl = null
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    this.saveSnapshot(_cloneDeep(this.entity))
    if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      // Clean vuex store for now
      this.clearSnapshots(this.entity.recurringRemarkId)
      this.clearSessionStorage()
      this.clearRouteMeta()
      next()
    }
  }
}
</script>
